/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useReducer } from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faEye,
  faSortAlphaUp,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { Seo } from '../../../modules/common/components/seo';
import { SectionHeader } from '../../../modules/common/components/section-header';

import lodash from 'lodash';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { INikkeCharacterNode } from '../../../modules/common/model/graphql-types';
import { NikkeSpecialitiesArray } from '../../../modules/nikke/character/model/nikke-specialities-array';

import '../../generic-page.scss';
import './harmony-cubes.scss';
import { Accordion } from 'react-bootstrap';
import { FilterBar } from '../../../modules/cs/common/components/filter-bar/filter-bar';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';

interface INikkeCharacterNodes {
  nodes: INikkeCharacterNode[];
}

interface INikkeCharacterEntry {
  allCharacters: INikkeCharacterNodes;
}

interface IProps {
  data: INikkeCharacterEntry;
}

const NikkeOlRec: React.FC<IProps> = ({ data }) => {
  const [visibleCharacters, setVisibleCharacters] = useState(
    data.allCharacters.nodes
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'R', value: 'r' },
          { label: 'SR', value: 'sr' },
          { label: 'SSR', value: 'ssr' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Electric',
            tooltip: 'Electric',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_electric.png"
                width={20}
                alt="Electric"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_fire.png"
                width={20}
                alt="Fire"
              />
            )
          },
          {
            value: 'Iron',
            tooltip: 'Iron',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_iron.png"
                width={20}
                alt="Iron"
              />
            )
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_water.png"
                width={20}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/element_wind.png"
                width={20}
                alt="Wind"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Assault Rifle',
            tooltip: 'Assault Rifle',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_ar.png"
                width={40}
                alt="Assault"
              />
            )
          },
          {
            value: 'Minigun',
            tooltip: 'Minigun',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_minigun.png"
                width={40}
                alt="Minigun"
              />
            )
          },
          {
            value: 'Rocket Launcher',
            tooltip: 'Rocket Launcher',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_rocket.png"
                width={40}
                alt="Rocket Launcher"
              />
            )
          },
          {
            value: 'Shotgun',
            tooltip: 'Shotgun',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_shotgun.png"
                width={40}
                alt="Shotgun"
              />
            )
          },
          {
            value: 'SMG',
            tooltip: 'SMG',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_smg.png"
                width={40}
                alt="SMG"
              />
            )
          },
          {
            value: 'Sniper Rifle',
            tooltip: 'Sniper Rifle',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/weapon_sniper.png"
                width={40}
                alt="Sniper Rifle"
              />
            )
          }
        ]
      },
      {
        key: 'class',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Attacker',
            tooltip: 'Attacker',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_attacker.png"
                width={20}
                alt="Attacker"
              />
            )
          },
          {
            value: 'Defender',
            tooltip: 'Defender',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_defender.png"
                width={20}
                alt="Defender"
              />
            )
          },
          {
            value: 'Supporter',
            tooltip: 'Supporter',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/class_support.png"
                width={20}
                alt="Supporter"
              />
            )
          }
        ]
      },
      {
        key: 'burst',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: '1',
            tooltip: 'Burst I',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_1.png"
                width={20}
                alt="Type I"
              />
            )
          },
          {
            value: '2',
            tooltip: 'Burst II',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_2.png"
                width={20}
                alt="Type II"
              />
            )
          },
          {
            value: '3',
            tooltip: 'Burst III',
            image: (
              <StaticImage
                src="../../../images/nikke/icons/type_3.png"
                width={20}
                alt="Type III"
              />
            )
          }
        ]
      },
      {
        key: 'manufacturer',
        type: 'dropdown',
        values: [
          { label: 'Any Manufacturer', value: 'all' },
          {
            label: 'Abnormal',
            value: 'abnormal'
          },
          {
            label: 'Elysion',
            value: 'elysion'
          },
          {
            label: 'Missilis',
            value: 'missilis'
          },
          {
            label: 'Pilgrim',
            value: 'pilgrim'
          },
          {
            label: 'Tetra',
            value: 'tetra'
          }
        ]
      },
      { key: 'speciality', type: 'dropdown', values: NikkeSpecialitiesArray },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.element.indexOf(activeFilters.element) > -1
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
      if (activeFilters.class && activeFilters.class !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.class.toLowerCase() === activeFilters.class.toLowerCase()
        );
      }
      if (activeFilters.manufacturer && activeFilters.manufacturer !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.manufacturer.toLowerCase() ===
            activeFilters.manufacturer.toLowerCase()
        );
      }
      if (activeFilters.burst && activeFilters.burst !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.burstType.toLowerCase() === activeFilters.burst.toLowerCase()
        );
      }
      if (activeFilters.speciality && activeFilters.speciality !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.specialities &&
            emp.specialities.length > 0 &&
            emp.specialities.indexOf(activeFilters.speciality) > -1
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.name > b.name ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    } else if (sortOption === 'Priority') {
      filtered = filtered.sort((a, b) =>
        a.olInfo.Priority < b.olInfo.Priority ? 1 : -1
      );
    }

    setVisibleCharacters(filtered);
    forceUpdate();
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: 'Priority', label: 'Sort by Priority' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    // eslint-disable-next-line prefer-destructuring
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }

  return (
    <DashboardLayout className={'generic-page characters-page'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>NIKKE Overload Gear (OL) - Recommendations</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_olrec.jpg"
            alt="Skill Investment"
          />
        </div>
        <div className="page-details">
          <h1>NIKKE Overload Gear (OL) - Recommendations</h1>
          <h2>
            Overload Gear (OL) recommendations for every character in NIKKE!
          </h2>
          <p>
            Last updated: <strong>20/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297004"></div>
      <SectionHeader title="Overload Gear - Recommendations" />
      <Accordion className="tier-list-accordion skills">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Glossary</Accordion.Header>
          <Accordion.Body>
            <p>
              This Overload (OL) Recommendations and Priority guide unravels in
              detail everything you need about a character in terms of priority,
              overload targets (effects), and order of acquisition, alongside
              some notes to explain our thinking process.
            </p>
            <p>
              For OL targets and order of acquisition, we decided to divide the
              target effects into several categories.
            </p>
            <ul>
              <li>
                <strong>Essential</strong>: With all gear combined, your unit
                must have the roll(s) prescribed as a minimum requirement.
                Example: Charge Speed on Alice. You cannot compromise on these
                rolls.
              </li>
              <li>
                <strong>Ideal</strong>: These rolls will improve your unit's
                performance significantly. Aim for 2 lines of Essential/Ideal
                per gear for maximum performance and the best Return on
                Investment (ROI). If you get 3, you are lucky. You can choose to
                only have 1 as well to save materials. Example: Elemental Damage
                on Scarlet.
              </li>
              <li>
                <strong>Passable:</strong> Low-priority line that has either
                limited value or the impact is not as strong as other rolls.
                Should this show up as a bonus together with a better line, you
                can choose to keep them. Example: Charge Speed on Red Hood.
              </li>
            </ul>
            <p>
              Overload effects are ordered by importance from Left to Right,
              which means anything on the left provides more value than the
              right. Effects that belong to the same group generate too similar
              an impact to make a difference (and are sometimes
              interchangeable). Try to follow this order as faithfully as
              possible for maximum mileage.
            </p>
            <p>
              <strong>Additional tips:</strong>
            </p>
            <ul>
              <li>
                Priority (PvE/PvP) isn't necessarily lower than Priority
                (Universal), which has no tag/label. It just indicates that this
                unit performs only in select types of content.
              </li>
              <li>
                If a suggested effect has the number ×2 next to it, that means
                you should get the effect on at least 2 gear. The same idea
                applies to other numbers.
              </li>
              <li>
                Focus on obtaining Essential(s) then you can start worrying
                about optimizing your rolls. Units require these to function,
                and the impact is too massive to ignore.
              </li>
              <li>
                If a unit has no Essential(s), that means their performance is
                not that dependent on OL, but they can still benefit from good
                effects.
              </li>
              <li>
                Remember that this is just a recommendation list, and attaining
                perfection is impossible. You will have to make some compromise,
                pick whichever rolls you desire the most, and conserve as much
                material as possible.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Priority</Accordion.Header>
          <Accordion.Body>
            <p>
              In terms of priority, we grade characters based on their
              performance in various content or game mode. Meta units should be
              prioritized because they hold a high amount of value; rerolling
              their effects is of the highest importance. Similarly, units of
              High priority should provide more value overall than units of
              Medium priority when it comes to to efficacy and return on
              investment. This does not necessarily mean units in lower
              categories are worse, but they might be less dependent on OL. For
              example, Dorothy is present in all Solo Raid runs and can be
              considered a staple, but she does not benefit from OL as much as
              Alice. On the contrary, Tove might not be seeing as much usage,
              but she would want OL to be less inconsistent.
            </p>
            <p>
              <strong>The metrics are as follows:</strong>
            </p>
            <ul>
              <li>How dependent is the unit on OL?</li>
              <li>How much impact will she bring after OL?</li>
              <li>Are there drawbacks or repercussions?</li>
            </ul>
            <p>The Priorities are as follows:</p>
            <ul>
              <li>Meta: Most Important Nikkes.</li>
              <li>High: Good in various content.</li>
              <li>Medium: Good in multiple situations in certain contents.</li>
              <li>
                Low: Can perform well but not as good as other units /
                Situationally Meta / Old Meta
              </li>
              <li>Very Low: Niche usage / Tribe Tower Units</li>
              <li>
                Priority with (PvE/PvP) written isn't necessarily lower than
                Priority (Universal), which has no tag/label. It just indicates
                that this unit performs best in select types of content. The PvE
                tag caters more to raid gameplay where multiple teams are
                required.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="employees-filter-bar nikke">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="nikke-skills">
        <div className="nikke-skills-row over header">
          <div
            className={`column character ${
              sortOption === '+fullName' ? 'active' : ''
            }`}
            onClick={() => setSortOption('+fullName')}
          >
            Character
            {sortOption && sortOption === '+fullName' && (
              <span className="icon">
                <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
              </span>
            )}
          </div>
          <div className="column suggestions essential">Essential</div>
          <div className="column suggestions ideal">Ideal</div>
          <div className="column suggestions passable">Passable</div>
          <div
            className={`column priority ${
              sortOption === 'Priority' ? 'active' : ''
            }`}
            onClick={() => setSortOption('Priority')}
          >
            Priority
            {sortOption && sortOption === 'Priority' && (
              <span className="icon">
                <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
              </span>
            )}
          </div>
        </div>
        <p className="mobile-title">Sorting:</p>
        <div className="mobile-sorting">
          <div
            className={`box character ${
              sortOption === '+fullName' ? 'active' : ''
            }`}
            onClick={() => setSortOption('+fullName')}
          >
            Name
            {sortOption && sortOption === '+fullName' && (
              <span className="icon">
                <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
              </span>
            )}
          </div>
          <div
            className={`box priority ${
              sortOption === 'Priority' ? 'active' : ''
            }`}
            onClick={() => setSortOption('Priority')}
          >
            Priority
            {sortOption && sortOption === 'Priority' && (
              <span className="icon">
                <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
              </span>
            )}
          </div>
        </div>
        {visibleCharacters.map((emp, index) => {
          return (
            <>
              <div className="nikke-row">
                <div className="nikke-skills-row over data">
                  <div className="column character">
                    <NikkeCharacter
                      slug={emp.slug}
                      mode="icon"
                      showLabel
                      enablePopover
                      onlyName
                    />
                  </div>
                  <div className="column suggestions pve">
                    <p className="on-mobile">Essential</p>
                    {emp.olInfo.Essential_1 ? (
                      <p>
                        <strong>{emp.olInfo.Essential_1}</strong>
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                    {emp.olInfo.Essential_2 && (
                      <p>
                        <strong>{emp.olInfo.Essential_2}</strong>
                      </p>
                    )}
                  </div>
                  <div className="column suggestions pvp">
                    <p className="on-mobile">Ideal</p>
                    {emp.olInfo.Ideal_1 ? (
                      <p>
                        <strong>{emp.olInfo.Ideal_1}</strong>
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                    {emp.olInfo.Ideal_2 && (
                      <p>
                        <strong>{emp.olInfo.Ideal_2}</strong>
                      </p>
                    )}
                    {emp.olInfo.Ideal_3 && (
                      <p>
                        <strong>{emp.olInfo.Ideal_3}</strong>
                      </p>
                    )}
                  </div>
                  <div className="column suggestions pvp">
                    <p className="on-mobile">Passable</p>
                    {emp.olInfo.Passable ? (
                      <p>
                        <strong>{emp.olInfo.Passable}</strong>
                      </p>
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                  <div className="column priority">
                    <p className="on-mobile">Priority</p>
                    {emp.olInfo.Priority ? (
                      <>
                        {emp.olInfo.Priority >= 11 && (
                          <p className={`prio prio-11`}>Meta</p>
                        )}
                        {emp.olInfo.Priority >= 10 &&
                          emp.olInfo.Priority < 11 && (
                            <p className={`prio prio-10`}>High</p>
                          )}
                        {emp.olInfo.Priority === 9 && (
                          <p className={`prio prio-10`}>High (PVE)</p>
                        )}
                        {emp.olInfo.Priority === 8 && (
                          <p className={`prio prio-10`}>High (PVP)</p>
                        )}
                        {emp.olInfo.Priority === 7 && (
                          <p className={`prio prio-5`}>Medium</p>
                        )}
                        {emp.olInfo.Priority === 6 && (
                          <p className={`prio prio-5`}>Medium (PVE)</p>
                        )}
                        {emp.olInfo.Priority === 5 && (
                          <p className={`prio prio-5`}>Medium (PVP)</p>
                        )}
                        {emp.olInfo.Priority === 4 && (
                          <p className={`prio prio-3`}>Low</p>
                        )}
                        {emp.olInfo.Priority === 3 && (
                          <p className={`prio prio-3`}>Low (PVE)</p>
                        )}
                        {emp.olInfo.Priority === 2 && (
                          <p className={`prio prio-3`}>Low (PVP)</p>
                        )}
                        {emp.olInfo.Priority === 1 && (
                          <p className={`prio prio-1`}>Very Low</p>
                        )}
                        {emp.olInfo.Priority === 0 && <p className="prio">-</p>}
                      </>
                    ) : (
                      <>-</>
                    )}
                  </div>
                </div>
                <div className={`explanation`}>
                  <p className="on-mobile">Notes</p>
                  {emp.olInfo.Comments ? (
                    <p>{emp.olInfo.Comments}</p>
                  ) : (
                    <p>-</p>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
      {visibleCharacters.length === 0 && (
        <div className="no-results">
          <StaticImage
            src="../../../images/counterside/no_results.png"
            alt="No results"
          />
          <p>No characters found. Try changing your filter.</p>
        </div>
      )}
    </DashboardLayout>
  );
};

export default NikkeOlRec;

export const Head: React.FC = () => (
  <Seo
    title="Overload Gear (OL) - Recommendations | NIKKE | Prydwen Institute"
    description="Overload Gear (OL) recommendations for every character in NIKKE!"
    game="nikke"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulNikkeCharacter(
      filter: { isReviewPending: { ne: true } }
      sort: { fields: name, order: ASC }
    ) {
      nodes {
        id
        updatedAt
        createdAt
        unitId
        name
        slug
        rarity
        element
        weapon
        class
        burstType
        manufacturer
        squad
        specialities
        isNew
        isReviewPending
        olInfo {
          Name
          Priority
          Essential_1
          Essential_2
          Ideal_1
          Ideal_2
          Ideal_3
          Passable
          Comments
        }
      }
    }
  }
`;
